html,
body {
  overscroll-behavior-x: none;
  &::-webkit-scrollbar {
    width: 10px;
    padding-right: 50px !important;
  }
  image-rendering: optimizeQuality !important;
  &::-webkit-scrollbar-track {
    background: rgba(179, 179, 179, 0.336) !important;
    margin-top: 5px !important;
    margin-right: 10px !important;
    margin-bottom: 5px !important;

    border-radius: 2px 2px 2px 2px;

    //border-left: 1px solid rgba(117,115,109,0.43);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(241, 241, 241, 0.712);

    border-radius: 2px 2px 2px 2px;
  }
}
#root {
  min-height: 100vh;
  margin: 0;
}
body {
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #1b1b1b 0%, #1d2125 74%);
  color: white;
}

.test {
  color: white;
}
// font size utilities
.font-size-1 {
  font-size: 1.1em;
}
.font-size-2 {
  font-size: 1.2em;
}
.font-size-3 {
  font-size: 1.3em;
}
.font-size-4 {
  font-size: 1.4em;
}
.font-size-5 {
  font-size: 1.5em;
}
.content-fac {
  font-size: medium !important;
}
// Framer Box shadow
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 0px 20px 0px;
}
.dom-box-shadow {
  transform: scale(1);
  animation: pulse-dom 2s infinite;
}
.logo-image-proc {
  image-rendering: auto;
}
.error-style {
  background-color: rgb(253, 223, 223) !important;
  box-shadow: 0px 0px 20px 0px rgba(245, 0, 0, 0.349);
}

.btn-link {
  color: #26808f !important;
}

.Toastify {
  position: fixed;
  z-index: 99999 !important;
}

.Toastify__toast {
  box-shadow: inset -1px -1px 4px 1px rgba(26, 26, 26, 0.418),
    inset 1px 1px 8px 1px rgba(199, 199, 199, 0.082), 3px 3px 10px 6px #01010175,
    -2px -4px 9px 3px #dadada0c !important;
}
.Toastify__toast-theme--dark {
  background-color: hsla(0, 0%, 11%, 0.8) !important;
  border-radius: 15px !important;
}
.Toastify__toast--success {
  background-color: rgba(8, 100, 100, 0.863) !important;
  border-radius: 15px !important;
  z-index: 99999 !important;
}
.Toastify__toast--error {
  background-color: rgba(240, 9, 1, 0.85) !important;
  border-radius: 15px !important;
  z-index: 99999 !important;
}
.Toastify__progress-bar--dark {
  background-color: rgba(13, 143, 163, 0.925) !important;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgb(9, 48, 121) 35%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
}
@keyframes pulse-dom {
  0% {
    box-shadow: rgba(0, 162, 255, 0.15) 0px 0px 10px 15px;
    box-shadow: rgba(0, 162, 255, 0.45) 0px 0px 10px 8px;
  }

  50% {
    box-shadow: rgba(0, 162, 255, 0) 0px 0px 10px 8px;
  }

  100% {
    box-shadow: rgba(0, 162, 255, 0.15) 0px 0px 10px 15px;
    box-shadow: rgba(0, 162, 255, 0.45) 0px 0px 10px 8px;
  }
}
.dom-box-shadow2 {
  animation: pulse-dom2 3s infinite;
}
.btn {
  box-shadow: inset -1px -1px 4px 1px rgba(26, 26, 26, 0.418),
    inset 1px 1px 8px 1px rgba(199, 199, 199, 0.082), 3px 3px 10px 6px #01010175,
    -2px -4px 9px 3px #dadada0c;
}
.btn-info {
  border: none !important;
}
.btn-primary {
  border: none !important;
}
.btn-success {
  border: none !important;
}
.btn-warning {
  border: none !important;
}
.btn-danger {
  border: none !important;
}
.btn:focus,
.btn:focus-visible {
  box-shadow: inset -1px -1px 4px 1px rgba(26, 26, 26, 0.418),
    inset 1px 1px 8px 1px rgba(199, 199, 199, 0.082), 3px 3px 10px 6px #01010175,
    -2px -4px 9px 3px #dadada0c;
  &.btn-none {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
}
.btn-none {
  box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0);
}
@keyframes pulse-dom2 {
  0% {
    box-shadow: rgba(0, 162, 255, 0.25) 0px 0px 10px 12px;
    box-shadow: rgba(0, 162, 255, 0.65) 0px 0px 10px 6px;
  }

  50% {
    box-shadow: rgba(0, 162, 255, 0.25) 0px 0px 10px 6px;
    box-shadow: rgba(0, 162, 255, 0.65) 0px 0px 10px 6px;
  }

  100% {
    box-shadow: rgba(0, 162, 255, 0.25) 0px 0px 10px 12px;
    box-shadow: rgba(0, 162, 255, 0.65) 0px 0px 10px 6px;
  }
}
.mute-box-shadow {
  animation: pulse-mute 3s infinite;
}

@keyframes pulse-mute {
  0% {
    box-shadow: rgba(255, 0, 16, 0.15) 0px 0px 10px 15px;
    box-shadow: rgba(255, 0, 16, 0.45) 0px 0px 10px 8px;
  }

  50% {
    box-shadow: rgba(255, 0, 16, 0.05) 0px 0px 10px 8px;
  }

  100% {
    box-shadow: rgba(255, 0, 16, 0.15) 0px 0px 10px 15px;
    box-shadow: rgba(255, 0, 16, 0.45) 0px 0px 10px 8px;
  }
}
.error-box-shadow {
  animation: pulse-error 3s infinite;
}

@keyframes pulse-error {
  0% {
    box-shadow: rgba(255, 0, 16, 0.9) 0px 0px 5px 7px;
    box-shadow: rgba(255, 0, 16, 0.158) 0px 0px 25px 4px;
  }

  50% {
    box-shadow: rgba(255, 0, 16, 0.05) 0px 0px 15px 4px;
  }

  100% {
    box-shadow: rgba(255, 0, 16, 0.9) 0px 0px 5px 7px;
    box-shadow: rgba(255, 0, 17, 0.158) 0px 0px 25px 4px;
  }
}
ol.letter-ol {
  list-style-type: none;
}

.letter-ol li:before {
  content: " (" counter(section, lower-alpha) ") ";
}
.letter-ol li {
  counter-increment: section;
}
.medi .carousel-control-next-icon {
  display: none;
}
.medi .carousel-control-prev-icon {
  display: none;
}
.medi .carousel-indicators {
  display: none;
}
.ref-edit q {
  font-style: italic;
  quotes: "" "";

  &:before {
    content: open-quote;
  }
  &:after {
    content: close-quote;
  }
}
q {
  font-style: italic;
  quotes: "" "";

  &:before {
    content: open-quote;
  }
  &:after {
    content: close-quote;
  }
}
@media screen and (max-width: 600px) {
  .small-logo {
    width: 50px !important;
  }
}
@media screen and (max-width: 600px) {
  .landing-icon {
    font-size: 20px;
    vertical-align: text-bottom !important;
  }
}
.landing-icon {
  vertical-align: text-bottom !important;
  margin-bottom: 0.1em !important;
}
// Framer blue color
.bg-info2 {
  background-color: rgb(31, 166, 229);
}
.text-info2 {
  color: rgb(31, 166, 229);
}
.text-mustard {
  color: #f8f078;
}
.bg-mustard {
  background-color: #88833d8f;
}

// for scrolling effect
html {
  scroll-behavior: smooth;
  background-color: hsla(0, 0%, 0%, 0) !important;
}
audio::-webkit-media-controls-mute-button {
  all: initial;
  * {
    all: unset;
  }
  display: none !important;
}
audio::-webkit-media-controls {
  background-color: rgba(0, 0, 0, 0);
  color: white;
  max-width: 180px !important;
}

audio::-webkit-media-controls-enclosure {
  max-width: 245px !important;
}
audio::-webkit-media-controls-overlay-enclosure {
  max-width: 180px !important;
}
audio::-webkit-media-controls-time-remaining-display {
  color: white;
}
audio::-webkit-media-controls-timeline {
  width: 95px !important;

  color: white;
  audio::-internal-media-controls-segmented-track {
    width: 80px !important;
  }
}
audio::-webkit-media-controls-panel {
  max-width: 245px !important;

  color: white;
}

audio::-webkit-media-controls-current-time-display {
  color: white;
}
audio::-webkit-media-controls-enclosure {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}
audio::-webkit-media-controls-play-button {
  color: #007bff;
  border-color: #007bff;
  appearance: #007bff;
  all: initial;
  * {
    all: unset;
  }
}
audio::-webkit-media-controls-volume-slider {
  all: initial;
  * {
    all: unset;
  }
  display: none !important;
}
audio::-webkit-media-controls-volume-slider-container {
  all: initial;
  * {
    all: unset;
  }
  display: hidden !important;
}
audio::-webkit-media-controls-volume-control-hover-background {
  all: initial;
  * {
    all: unset;
  }
  display: none !important;
}
audio::-webkit-media-controls-volume-control-container {
  all: initial;
  * {
    all: unset;
  }
  display: none !important;
}
// Extra radius for
.btn-extra-radius {
  border-radius: 50px;
}

// Round containers and boxes
.round-box {
  border-radius: 15px;
}

// 3d effect for boxes with border
.box-3d {
  border-bottom-width: 2px !important;
  border-right-width: 2px !important;
}

// hover effects for clickable card inside of schedule
.clickable-card {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  border: 1px solid rgba(66, 66, 66, 0.959) !important;
  &:hover {
    border-color: hsla(204, 28%, 50%, 0.3) !important;
    background-color: hsla(204, 28%, 50%, 0.6);
  }
}
.admin-label {
  &:hover {
    color: rgb(11, 218, 250);
  }
}
.gray-filled {
  background-color: rgba(0, 0, 0, 0.2);
}
.gray-filled-1 {
  background-color: rgba(0, 0, 0, 0.1);
}
.gray-filled-2 {
  background-color: rgba(0, 0, 0, 0.3);
}
.gray-filled-3 {
  background-color: rgba(0, 0, 0, 0.5);
}
.gray-filled-4 {
  background-color: rgba(0, 0, 0, 0.7);
}

.selected-card {
  border-left: 5px solid #007bff !important;
}

.selected-card-bg {
  background-color: #007bff36;
}

// Current day marker component
.current-day-marker {
  border-bottom: 3px solid #007bff;
}

// Search bar (Theme Library)
.search-bar {
  // display: inline-flex !important;
  border: 1px solid rgb(124, 124, 124);
  input {
    &:focus {
      border: none !important;
    }
  }
}
.bg-success-td {
  background-color: rgba(92, 184, 92, 0.7);
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
.should-scroll {
  overflow-y: auto !important;

  max-height: 100%;
}

// Modal
.generic-modal {
  z-index: 2000;
  // background-color: white;

  max-height: 95vh !important;
  max-width: 95vw !important;

  .content {
    overflow-y: auto !important;
    &::-webkit-scrollbar {
      width: 10px;
      padding-right: 50px !important;
    }

    &::-webkit-scrollbar-track {
      background: rgba(179, 179, 179, 0.336) !important;
      margin-top: 5px !important;
      margin-right: 10px !important;
      margin-bottom: 5px !important;
      border-radius: 16px;

      //border-left: 1px solid rgba(117,115,109,0.43);
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(241, 241, 241, 0.712);

      border-radius: 16px;
    }
  }
  .modal-close-button {
    border-radius: 12px;
    width: 44px;
    height: 44px;

    right: 10px;
    top: 10px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    svg {
      font-size: 2.5em;
      transition: inherit;
    }
    &:hover {
      svg {
        font-size: 2em;
        transform: rotate(90deg);
      }
    }
  }
}
.modal-close-button {
  border-radius: 12px;
  width: 44px;
  height: 44px;

  right: 10px;
  top: 10px;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  svg {
    font-size: 2.5em;
    transition: inherit;
  }
  &:hover {
    svg {
      font-size: 2em;
      transform: rotate(90deg);
    }
  }
  svg {
    font-size: 1.5em;
    transition: inherit;
  }
}

.ReactModal__Overlay {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

// Modal fade animation
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: rgba(138, 138, 138, 0.418) !important;
  backdrop-filter: blur(3px) !important;
  -webkit-backdrop-filter: blur(3px);
}

.ReactModal__Blackout--open .ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.91) !important;
}
.ReactModal__Blackout--open .ReactModal__Overlay {
  transition: opacity 3000ms ease-in-out;
}
.ReactModal__Blackout--open .ReactModal__Overlay--before-close {
  transition: opacity 3000ms ease-out;
  opacity: 0;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

// Prevent unecessary scrolling when modal is open
.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
  .cues-container {
    z-index: -1 !important;
    * {
      z-index: inherit;
    }
  }
  .fixed-top {
    z-index: 0 !important;
  }
}
.text-fade {
  color: rgba(153, 153, 153, 0.219) !important;
}
.text-fade-less {
  color: rgba(55, 129, 158, 0.5) !important;
}
.text-fade-hover {
  &:hover {
    color: rgb(153, 153, 153) !important;
  }
}
.text-pop-info {
  color: #004577 !important;
}
.zoom-container {
  transition: width 0.5s ease-in;
}
.size-trans {
  transition: width 0.5s ease-in;
}
.content-container-transition-in {
  transition: opacity 1s ease-in;
}
.content-container-transition-out {
  transition: opacity 0.3s ease-in;
}
// Darken image
.img-darken {
  filter: brightness(75%);
}
.img-darken2 {
  filter: brightness(55%);
}
sup {
  margin-left: 5px;
}
.session-content {
  min-height: calc(100vh - 45.8px - 62px);
}
// Theme card
.theme-card {
  transition: 0.3s all ease-in-out;
  h5 {
    top: 45%;
    transition: 0.4s all ease-in-out !important;
  }
  img {
    filter: brightness(75%);
    transition: 0.3s all ease-in-out;
  }
  &:hover {
    img {
      filter: brightness(100%);
    }
    h5 {
      border-radius: 15px;
      padding: 0.25em 0.35em;
      color: black !important;
      background-color: rgba(255, 255, 255, 0.85);
      top: 5%;
    }
  }
}
.theme-card-disabled {
  transition: 0.3s all ease-in-out;
  h5 {
    top: 80%;
    transition: 0.4s all ease-in-out !important;
    background: rgba(100, 100, 100, 0.7);
    color: white !important;
  }
  img {
    filter: brightness(75%);
    transition: 0.3s all ease-in-out;
  }
  .show {
    visibility: hidden;
  }
  &:hover {
    .theme-card-disabled-div {
      background: rgba(100, 100, 100, 0.7);
    }
    .show {
      visibility: visible;
    }
    img {
      filter: brightness(100%);
    }
    h5 {
      transition: 0s ease-in-out !important;
      border-radius: 15px;
      padding: 0.25em 0.35em;
      color: white !important;
      background: none;
      top: 5%;
    }
  }
}

.chime {
  border: 2px solid black;
  background: rgba(0, 0, 254, 0.2);

  color: #0022ff;
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 7s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%230000fe' /%3E%3Cstop offset='25%25' stop-color='%230000fe' /%3E%3Cstop offset='50%25' stop-color='%230000fe' /%3E%3Cstop offset='100%25' stop-color='%230011be' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E")
    1;
}
.tcA {
  .theme-card-disabled-div {
    background: rgba(100, 100, 100, 0.7);
  }
  .show {
    visibility: visible;
  }
  img {
    filter: brightness(100%);
  }
  h5 {
    transition: 0s ease-in-out !important;
    border-radius: 15px;
    padding: 0.25em 0.35em;
    color: white !important;
    background: none;
    top: 5%;
  }
}

// Navigation arrow (theme library horizontal scroller)
.scroll-menu-arrow {
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #eee;
  font-size: 2.5em;
  transition: 0.3s all ease-in-out;
  div {
    align-items: center;
    display: flex;
  }
  &:hover {
    background-color: #bbb;
    font-size: 2.75em;
  }
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.596) !important;
  &:hover {
    color: rgba(255, 255, 255, 1) !important;
    border-radius: 2px 2px 2px 2px;
    // background: rgba(179, 179, 179, 0.25) ;
    // background: linear-gradient(0deg, rgba(179,179,179,0.45) 0%,rgba(179,179,179,0.15) 50%, rgba(179,179,179,0) 100%);

    //box-shadow:  rgba(0, 162, 255, .35) 3px 2px  6px 0px  ;
    border-bottom: 4px solid rgba(0, 162, 255, 0.432);
    // margin-bottom: 4px !important;
    &.btn-none:hover {
      border-bottom: 4px solid rgba(0, 162, 255, 0) !important;
    }
    padding-bottom: 0;
  }
  &.dropdown-toggle {
    &:hover {
      color: rgba(255, 255, 255, 1) !important;
      border-radius: 2px 2px 2px 2px;
      // background: rgba(179, 179, 179, 0.25) ;
      // background: linear-gradient(0deg, rgba(179,179,179,0.45) 0%,rgba(179,179,179,0.15) 50%, rgba(179,179,179,0) 100%);

      //box-shadow:  rgba(0, 162, 255, .35) 3px 2px  6px 0px  ;
      border-bottom: 4px solid rgba(0, 162, 255, 0.432);
      // margin-bottom: 4px !important;

      padding-bottom: 0 !important;
    }
  }
  &:active {
    color: rgb(255, 255, 255) !important;
    border-radius: 2px 2px 2px 2px;

    //box-shadow:  rgba(0, 162, 255, .35) 3px 2px  6px 0px  ;
    border-bottom: 4px solid rgba(0, 162, 255, 0.432);
  }
}
.no-marg {
  &:hover {
    margin-bottom: 4px !important;
  }
}
.no-marg.active {
  &:hover {
    margin-bottom: 0px !important;
    //margin-top: -4px;
  }
}
.btn-success {
  color: #ffffff;
}
.btn-success:hover {
  color: #ffffff;
}
.nav-no-dec {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  color: rgba(255, 255, 255, 0.596) !important;
  &:hover {
    color: rgba(255, 255, 255, 1) !important;
    border-radius: none !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0) !important;

    padding-bottom: 0;
  }
  &:focus {
    border-color: rgba(0, 0, 0, 0);
    box-shadow: none !important;
  }
}
.nav-no-dec-nest {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  color: rgba(255, 255, 255, 0.596) !important;
  &:hover {
    color: rgba(255, 255, 255, 1) !important;
    border-radius: none !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0) !important;

    padding-bottom: 0;
  }
}
.navbar-dark .navbar-nav .nav-link.active {
  color: rgb(255, 255, 255) !important;
  border-radius: 2px 2px 2px 2px;
  // background: rgba(179, 179, 179, 0.25) ;
  // background: linear-gradient(0deg, rgba(179,179,179,0.45) 0%,rgba(179,179,179,0.15) 50%, rgba(179,179,179,0) 100%)!important;

  //box-shadow:  rgba(0, 162, 255, .35) 3px 2px  6px 0px  ;
  border-bottom: 4px solid rgba(0, 162, 255, 0.432);
  padding-bottom: 0;
}
.fac-prom {
  background-color: rgba(0, 115, 133, 0.226);
  box-shadow: rgba(0, 162, 255, 0.082) 8px 6px 10px 0px;
  .text-fac {
    color: rgba(56, 186, 206, 0.719) !important;
  }
}
.logo-glow {
  box-shadow: rgba(0, 162, 255, 0.11) 0px 0px 4px 4px !important;
}
.text-fac {
  color: rgba(56, 186, 206, 0.719) !important;
}
// Prevent image from being dragged
.not-draggable {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

// Helps align Icon with text after
.icon-text {
  display: flex;
  align-items: center;
  :nth-child(1) {
    display: flex;
    align-items: center;
    margin-right: 0.25em;
  }
  :nth-child(2) {
    margin-right: 0.5em;
  }
}
.icon-text-tag {
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  font-size: 1.1em;
}

.icon-text-smaller {
  display: flex;
  align-items: center;
  :nth-child(1) {
    font-size: 1em;
    display: flex;
    align-items: center;
    margin-right: 0.2em;
  }
  // :nth-child(2) {
  //   margin-right: 0.5em;
  // }
}

// Helps align text with icon after
.text-icon {
  display: flex;
  align-items: center;
  :nth-child(1) {
    margin-right: 0.5em;
  }
  :nth-child(2) {
    font-size: 1.1em;
    display: flex;
    align-items: center;
    margin-right: 0.3em;
  }
}
.useBorder {
  border: 1px solid rgba(66, 66, 66, 0.959) !important;
}
.use-border-d {
  border: 1px solid rgb(47, 47, 47) !important;
}
.use-border-no-bottom {
  border-top: 1px solid rgba(66, 66, 66, 0.959) !important;
  border-left: 1px solid rgba(66, 66, 66, 0.959) !important;
  border-right: 1px solid rgba(66, 66, 66, 0.959) !important;
}
.border-darker-danger {
  border: 1.5px solid rgba(255, 71, 65, 0.918) !important;
}
.use-border-zero {
  border: 0px solid rgba(0, 0, 0, 0) !important;
}
// RunnningSessionContainer navbar style
.running-session-navbar {
  .active {
    background-color: rgba(13, 143, 163, 0.925) !important;
    color: white;
  }
  div {
    transition: 0.3s all ease-in-out;
    background-color: rgba(104, 104, 104, 0.9) !important;
    &:hover {
      background-color: rgba(0, 0, 0, 0.35) !important;
    }
  }
}
.font-grey {
  color: rgba(80, 79, 79, 0.788) !important;
}
// Multisection container css for the content inside of main
.mutilsection-container-content {
  font-size: 1.3em;
  .music {
    h1 {
      font-size: 1.4em !important;
    }

    .lyrics {
      span {
        display: block;
        width: 100%;
      }
    }
  }
  .seethruboxOn {
    background-color: rgba(92, 184, 92, 0.7) !important;
    background: linear-gradient(
      90deg,
      rgba(35, 173, 35, 0.664) 0%,
      rgba(35, 173, 35, 0.712) 35%,
      rgba(35, 173, 35, 0.85) 100%
    ) !important;
    border: 1px solid rgba(21, 177, 21, 0.65) !important;
  }
  .seethruboxOff {
    background-color: rgba(217, 83, 79, 0.7);
    background: linear-gradient(
      90deg,
      rgba(235, 36, 29, 0.664) 0%,
      rgba(235, 36, 29, 0.712) 35%,
      rgba(235, 36, 29, 0.85) 100%
    ) !important;
    border: 1px solid rgba(235, 36, 29, 0.65);
  }
  p {
    margin-bottom: 1.25em;

    span {
      font-size: 1.6em;
    }
  }

  ul {
    margin: 1.25em 0;
    li {
      margin: 0.25em 0;
    }
  }

  blockquote {
    border-left: 5px solid rgba(0, 0, 0, 0.25);
    font-weight: 500;
    margin-left: 0.5em;
    padding-left: 0.5em !important;
    padding-top: 0.25em !important;
    padding-bottom: 0.25em !important;
  }
}

.mutilsection-container-arrow {
  transition: 0.3s all ease-in-out;
  font-size: 3em;
  color: #007bff;
  &:hover {
    color: #0156b1;
    animation: bounce infinite 0.5s;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.profile-card-animation {
  animation: expand ease-out 1s;
}
@keyframes expand {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(0.01, 1);
    opacity: 0.3;
  }
  // 75% {
  //   transform: scaleY(0.5);
  // }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.chat-user {
  a {
    color: rgb(167, 167, 167);
  }
  &.chat-user-you {
    text-align: right !important;
  }
  margin-right: 5px;
  margin-left: 5px;
  overflow-wrap: break-word !important;
}
.box-shadow-light {
  box-shadow: rgba(255, 255, 255, 0.116) -3px -3px 25px 0px;
}
.speech-bubble-r-ds {
  background: rgba(20, 100, 112, 0.75);
  border: 1px solid rgba(0, 55, 63, 0.75);
  border-radius: 7px;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 0;
  box-shadow: 4px 4px 5px 0 rgba(177, 177, 177, 0.116);
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 3px 0 3px auto;
  max-width: 400px;
  min-width: 170px;
  padding: 0 15px 5px 10px;
  position: relative;
  text-align: left !important;
  .chat-reply-to {
    background: rgba(32, 41, 43, 0.75);
    border: 1px solid rgba(0, 66, 77, 0.801);
    border-radius: 7px;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 0;
    box-shadow: 4px 4px 5px 0 rgba(177, 177, 177, 0.116);
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 3px 0 3px auto;
    max-width: 400px;
    min-width: 170px;
    padding: 0 15px 5px 10px;
    position: relative;
    text-align: left !important;
  }
  p {
    margin-bottom: 10px;

    :last-of-type {
      margin-bottom: 0;
    }
  }
}
.replying-to-input {
  border-top: 3px solid rgba(21, 143, 161, 0.75);
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.808);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.speech-bubble-l-ds {
  background: rgba(32, 41, 43, 0.75);
  border: 1px solid rgba(0, 66, 77, 0.801);
  border-radius: 7px;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 0;
  box-shadow: -4px 4px 5px 0 rgba(177, 177, 177, 0.116);
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 3px auto 3px 0;
  max-width: 360px;
  min-width: 170px;
  padding: 0 15px 5px 10px;
  position: relative;
  .chat-reply-to {
    background: rgba(20, 100, 112, 0.75);
    border: 1px solid rgba(0, 55, 63, 0.75);
    border-radius: 7px;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 0;
    box-shadow: 4px 4px 5px 0 rgba(177, 177, 177, 0.116);
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 3px 0 3px auto;
    max-width: 400px;
    min-width: 170px;
    padding: 0 15px 5px 10px;
    position: relative;
    text-align: left !important;
  }
  p {
    margin-bottom: 10px;

    :last-of-type {
      margin-bottom: 0;
    }
  }
}

// Role card when adding member or facilitator
.role-card-add {
  background-color: rgba(0, 0, 0, 0.15) !important;
  transition: 0.3s all ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.4) !important;
  }
}

// Fixed sidebar (for schedule page)
.sidebar {
  position: fixed;
  top: 10%;
  bottom: 10%;
  right: 0;
  z-index: 0;
  padding: 56px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
.bg-nav-clear {
  background-color: rgba(65, 65, 65, 0.342);
  background-image: radial-gradient(
    circle,
    hsla(0, 0%, 9%, 0.171),
    rgba(32, 36, 41, 0.171)
  );
  backdrop-filter: blur(3px) !important;
  -webkit-backdrop-filter: blur(3px);
}
.bg-glass {
  box-shadow: rgba(231, 231, 231, 0.096) 3px 2px 6px 0px !important;
  background-color: rgba(204, 204, 204, 0.514);
  background-image: radial-gradient(
    circle,
    hsla(0, 0%, 25%, 0.171),
    rgba(28, 28, 28, 0.295)
  );
}
.bg-zero {
  background-color: rgba(0, 0, 0, 0);
}
.bg-cleard {
  background-color: hsla(0, 0%, 0%, 0.3);
  color: white !important;
  backdrop-filter: blur(3px) !important;
  -webkit-backdrop-filter: blur(3px);
}
.bg-clear-mid {
  background-color: rgba(124, 124, 124, 0.76);
  color: white !important;
}
.bg-cleard-menu {
  background-color: rgba(0, 0, 0, 0.767);
  color: white !important;
  backdrop-filter: blur(6px) !important;
  -webkit-backdrop-filter: blur(6px);
}
.bg-cleard-modal {
  background-color: rgba(0, 0, 0, 0.623);
  color: white !important;
  backdrop-filter: blur(3px) !important;
  -webkit-backdrop-filter: blur(3px);
}
.bg-cleard-abs {
  background-color: rgba(0, 0, 0, 0.623);
  color: white !important;
}

.bg-clearl {
  background-color: rgba(241, 241, 241, 0.6);
}
.bg-clear {
  background-color: #b3b3b321;
  border-color: rgba(153, 153, 153, 0.21) !important;
  color: #fff !important;
  &.selected-card {
    border-left: 4px solid hsla(204, 88%, 50%, 0.6) !important;
    border-bottom: 3px solid hsla(204, 88%, 50%, 0.6) !important;
    border-top: 3px solid hsla(204, 88%, 50%, 0.6) !important;
    background-color: hsla(0, 0%, 0%, 0.25);
  }
  &.gray-filled {
    background-color: hsla(0, 0%, 0%, 0.8);
  }
  &.clickable-card {
    cursor: pointer;
    border: 1px solid rgba(66, 66, 66, 0.959) !important;
    transition: 0.3s all ease-in-out;
    &:hover {
      border-color: hsla(204, 28%, 50%, 0.3) !important;
      background-color: hsla(204, 28%, 50%, 0.6);
    }
    &.selected-card {
      border-left: 8px solid hsla(204, 88%, 50%, 0.6) !important;
      border-bottom: 3px solid hsla(204, 88%, 50%, 0.6) !important;
      border-top: 3px solid hsla(204, 88%, 50%, 0.6) !important;
    }
  }
}
.bg-selected {
  border-color: hsla(204, 28%, 50%, 0.3) !important;
  background-color: hsla(204, 28%, 50%, 0.6);
}
.bg-clearWithHov {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  &:hover {
    border-color: hsla(204, 28%, 50%, 0.3) !important;
    background-color: hsla(204, 28%, 50%, 0.6) !important;
  }
}
.link-preview {
  .Secondary {
    color: #fff !important;
  }
  .Image {
    display: inline-block !important;

    height: auto !important;

    width: 32% !important;
    border-radius: 7px !important;
  }
  .LowerContainer {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    flex-direction: row !important;

    width: 66% !important;
  }
  .Title {
    color: #fff !important;
  }
  &.Container {
    min-width: 340px;
    max-width: 360px;
    flex-direction: row !important;
    max-height: 100% !important;

    flex-wrap: nowrap !important;
    position: relative !important;

    text-overflow: ellipsis !important;
    background-color: hsla(0, 0%, 70%, 0.25) !important;
    border-color: hsla(0, 0%, 60%, 0.21) !important;
    cursor: pointer;
    color: rgb(255, 255, 255) !important;
    transition: 0.3s all ease-in-out;
    &:hover {
      background-color: hsla(0, 0%, 70%, 0.25) !important;

      color: #000 !important;
    }
  }
}
.bg-clearWithHov2 {
  cursor: pointer;
  transition: all 0.5s ease-out;
  &:hover {
    transition: all 0.5s ease-out;
    border-color: hsla(204, 28%, 50%, 0.3) !important;
    background-color: #07536699;
    box-shadow: inset -1px -1px 4px 1px rgba(26, 26, 26, 0.418),
      inset 1px 1px 8px 1px rgba(199, 199, 199, 0.082),
      3px 3px 10px 6px #01010175, -2px -4px 9px 3px #dadada0c,
      5px 3px 5px 2px #07536699;

    // transform: translateY(-1px);
  }
}

.pop-over-text {
  &:hover {
    color: rgb(20, 100, 112) !important;
  }
}
/* if screen size of relative to phone and ipad, the width will be col-12, otherwise 40percent of view port. ipad (768px) is included, add 1px to it */
@media only screen and (min-width: 769px) {
  .memberProfileCard {
    width: 70vw !important;
  }
}
$vh: calc(100vh);
$navs: 90px;
$contHeight: calc(#{$vh} - #{$navs} - 16px);
// Session theme transition between notes open and close
.note-transition {
  transition: 0.5s all ease-in-out;
  min-height: $contHeight !important;
}
.ReactModal__Body--open {
  .form-group {
    div {
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: white;
      }
      &::-webkit-scrollbar-thumb {
        background: grey;
      }
    }
  }
}
.muteMenuScroll {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(179, 179, 179, 0.25) !important;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(241, 241, 241, 0.6);
  }
}
.rounded-scroll {
  &::-webkit-scrollbar {
    width: 10px;
    padding-right: 50px !important;
  }

  &::-webkit-scrollbar-track {
    background: rgba(179, 179, 179, 0.336) !important;
    margin-top: 5px !important;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
    border-radius: 16px;

    //border-left: 1px solid rgba(117,115,109,0.43);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(241, 241, 241, 0.712);

    border-radius: 16px;
  }
}

// Bootstrap does not provide a width 0
.w-0 {
  width: 0;
}

.h-0 {
  height: 0;
}
.h-1 {
  height: 1px;
}

.theme-page-divider {
  white-space: nowrap;
  overflow: hidden;
}

.theme-page-divider:before,
.theme-page-divider:after {
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
}

.theme-page-divider:before {
  width: 0;
}

.theme-page-divider:after {
  margin-left: 50px;
  width: 100%;
}

.sensory-img {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
}

// Default transition for any element
.default-transition {
  transition: 0.3s all ease-in-out;
}
.notBlob {
  border-radius: 50%;
  margin: 10px;
  height: 40px;
  width: 40px;
  font-size: 100%;
}
.blob {
  background: rgba(15, 184, 0, 0);
  border-radius: 50%;
  margin: 10px;
  height: 40px;
  width: 40px;
  font-size: 100%;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(15, 184, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(15, 184, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(15, 184, 0, 0);
  }
}

//barrel roll easter egg
.flashing-lights {
  animation: flash-boom 2s 3;
  animation-delay: 0.15s;
}
@keyframes flash-boom {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

.barrel-roll {
  animation: rotar 5s 1;
  -moz-animation: rotar 5s 1;
  -o-animation: rotar 5s 1;
  -webkit-animation: rotar 5s 1;
}
@keyframes rotar {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
@-moz-keyframes rotar {
  from {
    -moz-transform: rotateZ(0deg);
  }
  to {
    -moz-transform: rotateZ(360deg);
  }
}
@-o-keyframes rotar {
  from {
    -o-transform: rotateZ(0deg);
  }
  to {
    -o-transform: rotateZ(360deg);
  }
}
@-webkit-keyframes rotar {
  from {
    -webkit-transform: rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(360deg);
  }
}
.typing-container {
  border-radius: 7px;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 0;
  box-shadow: -4px 4px 5px 0 rgba(177, 177, 177, 0.116);
  background: rgba(32, 41, 43, 0.75);
  border: 1px solid rgba(0, 66, 77, 0.801);
}
.typing--blue {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin-left: 2px;
  margin-right: 2px;
  display: inline-block;
  background-color: #cacaca;
  animation: 1.2s typing-dot ease-in-out infinite;
}
.typing-dot:nth-of-type(2) {
  animation-delay: 0.15s;
}
.typing-dot:nth-of-type(3) {
  animation-delay: 0.25s;
}
@keyframes typing-dot {
  15% {
    transform: translateY(-35%);
    opacity: 0.5;
  }
  30% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.icey-1 {
  background: rgba(32, 163, 183, 0.171);
  border: 1px solid rgba(17, 85, 124, 0.959) !important;
  backdrop-filter: blur(12px) !important;
  -webkit-backdrop-filter: blur(12px);
  animation: 10s shimmer-1 infinite;
}
.icey-2 {
  background: rgba(129, 190, 226, 0.178);
  backdrop-filter: blur(12px) !important;
  -webkit-backdrop-filter: blur(12px);
}
.icey-3 {
  background: rgba(0, 91, 151, 0.171);
  backdrop-filter: blur(12px) !important;
  -webkit-backdrop-filter: blur(12px);
}
@keyframes shimmer-1 {
  0% {
    background: rgba(32, 115, 183, 0.171);
  }
  50% {
    background: rgba(106, 138, 175, 0.329);
  }
  75% {
    background: rgba(32, 135, 183, 0.171);
  }
  100% {
    background: rgba(0, 96, 151, 0.171);
  }
}
@keyframes shimmer-2 {
  20% {
    background: rgba(32, 128, 183, 0.171);
  }
  60% {
    background: rgba(151, 183, 209, 0.329);
  }
  80% {
    background: rgba(100, 131, 151, 0.171);
  }
  100% {
    background: rgba(70, 153, 209, 0.329);
  }
}

.side-nav-nub {
  transition: width 5s ease-out !important;
}
.side-nav {
  background: rgba(32, 41, 43, 0.918);
  border-right: 1px solid rgba(23, 66, 73, 0.801);
}
.side-nav-link {
  color: #eee;
  &:hover {
    color: rgba(133, 224, 240, 0.801);
    text-decoration: none;
  }
}
.side-nav-link.active {
  color: rgba(133, 224, 240, 0.801);
  text-decoration: none;
}
.login-link {
  color: rgba(6, 184, 211, 0.897) !important;
  &:hover {
    color: rgba(177, 229, 238, 0.801) !important;
  }
}
.btn-tab {
  color: rgba(255, 255, 255, 1) !important;
  &:hover {
    border-radius: 4px 4px 0px 0px;
    background: rgba(179, 179, 179, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 162, 255, 0.432) 0%,
      rgba(0, 162, 255, 0.068) 10%,
      rgba(0, 162, 255, 0) 25%
    ) !important;
    transition: all 0.5s ease-out;
    border-bottom: 4px solid rgba(0, 162, 255, 0.432);
    padding-bottom: 0;
  }
}
.btn-tab-selected {
  color: rgba(255, 255, 255, 1) !important;
  border-bottom: 4px solid rgba(0, 162, 255, 0.432);
  padding-bottom: 0;
}
.btn-tab-unselected {
  color: rgba(255, 255, 255, 1) !important;
  border-bottom: 4px solid rgba(110, 110, 110, 0.432);
  padding-bottom: 0;
}
.member-slide-h {
  transition: all 0.7s ease;
}
.chat-slider-out {
  transition: width 0.5s ease-out 0.5s;
}
.op-helper {
  height: 543px;
  opacity: 1 !important;
}
.not-op-helper {
  height: 0px;
  opacity: 0.1 !important;
}
.chat-slider-inner {
  transition: height 0.5s, opacity 2s ease-in; /* It slowly shows the element as we place the mouse on the box and when the time reaches 2 seconds, it is fully visible. */
  -webkit-transition: height 0.5s, opacity 2s ease-in;
  -moz-transition: height 0.5s, opacity 2s ease-in;
}
.chat-slider-inner-out {
  transition: width 0.5s ease-out, height 0.5s ease 0.5s, opacity 2s ease-in; /* It slowly shows the element as we place the mouse on the box and when the time reaches 2 seconds, it is fully visible. */
  -webkit-transition: width 0.5s ease-out, height 0.5s ease 0.5s,
    opacity 2s ease-in;
  -moz-transition: width 0.5s ease-out, height 0.5s ease 0.5s,
    opacity 2s ease-in;
}
.chat-slider-in {
  transition: width 0.25s ease-in;
}
.grow-slow {
  transition: width 0.5s ease-in;
}
.animated-background {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f81e;
  background: linear-gradient(
    to right,
    #eeeeee54 8%,
    #bbbbbbbe 18%,
    #eeeeee54 33%
  );
  background-size: 800px 104px;
  height: 70px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.form-control-disabled {
  background-color: #333 !important;
  color: white !important;
}

.tag-gradient {
  background: -webkit-linear-gradient(
    290deg,
    rgb(85, 212, 231),
    rgb(171, 116, 223)
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.hover-glow {
  &:hover {
    background-color: rgba(32, 163, 183, 0.308) !important;
  }
}

$melt_time: 3s;
$delay: 1s;
$anim_type: ease-in;
$cube_size: 30px;

.cube {
  position: absolute;
  left: 50%;
  top: -50%;
  width: $cube_size;
  height: $cube_size;
  transform-style: preserve-3d;
  transform: rotateX(-30deg) rotateY(35deg) translate(-70%, -70%);

  > div {
    position: absolute;
    left: 30%;
    top: -30%;
    animation: melt $melt_time $anim_type $delay infinite reverse;
    transform-style: preserve-3d;
    transform: translate3d(0, calc($cube-size / 2), 0) scaleY(0);

    > div {
      position: absolute;
      left: 50%;
      top: -30%;
      width: $cube_size;
      height: $cube_size;
      border-radius: calc($cube-size / 12);
      background: rgba(221, 221, 221, 0.2);
      background: linear-gradient(
        rgba(237, 237, 237, 0.2),
        rgba(153, 153, 153, 0.1)
      );
      box-shadow: 0 0 4px rgba(255, 255, 255, 0.2),
        inset 0 0 4px rgba(255, 255, 255, 0.2);
    }
  }
}

.cube > div > div:nth-child(1) {
  transform: translate3d(0, 0, calc($cube-size / 2));
}
.cube > div > div:nth-child(2) {
  transform: rotateX(90deg) translate3d(0, 0, calc($cube-size / 2));
}
.cube > div > div:nth-child(3) {
  transform: rotateX(180deg) translate3d(0, 0, calc($cube-size / 2));
}
.cube > div > div:nth-child(4) {
  transform: rotateX(270deg) translate3d(0, 0, calc($cube-size / 2));
  animation: puddle $melt_time $anim_type $delay infinite reverse;
}
.cube > div > div:nth-child(5) {
  transform: rotateY(90deg) translate3d(0, 0, calc($cube-size / 2));
}
.cube > div > div:nth-child(6) {
  transform: rotateY(-90deg) translate3d(0, 0, calc($cube-size / 2));
}

@keyframes melt {
  0% {
    transform: translate3d(0, 0, 0) scaleY(1);
  }
  90% {
    transform: translate3d(0, calc($cube-size / 2), 0) scaleY(0.01);
  }
  100% {
    transform: translate3d(0, calc($cube-size / 2), 0) scaleY(0);
  }
}
@keyframes puddle {
  100% {
    box-shadow: 0px 0px 0 calc($cube-size / 1.3) rgba(255, 255, 255, 0.2);
  }
}

.demo-wrapper {
  padding: 10px;
  background: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  resize: horizontal;

  overflow: none;
  max-width: 100%;
}
.demo-wrapper .video-chat {
  padding-top: 56.35%;
}

.fullscreen-modal.modal-dialog {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: papayawhip;
}

.fullscreen-modal .modal-content {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rebeccapurple;
}
.all-the-way-back {
  z-index: -100000 !important;
  pointer-events: none;
}

.loading {
  $colors: #7ef9ff, #89cff0, #4682b4, #0f52ba, #11117e;
  display: flex;

  .dot-blue {
    position: relative;
    width: 10px;
    height: 10px;
    margin: 0.8em;
    border-radius: 50%;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: inherit;
      border-radius: inherit;
      animation: wave 2s ease-out infinite;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        background: nth($colors, $i);

        &::before {
          animation-delay: $i * 0.2s;
        }
      }
    }
  }
}

@keyframes wave {
  50%,
  75% {
    transform: scale(2.5);
  }

  80%,
  100% {
    opacity: 0;
  }
}
.hover-blur {
  position: absolute;
  width: 100%;
  height: 100%;

  &:hover {
    backdrop-filter: blur(3px) !important;
    -webkit-backdrop-filter: blur(3px);
  }
}
.blur-filter {
  position: relative;
}
.blur-filter > .blur-filter-in {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(3px) !important;
  -webkit-backdrop-filter: blur(3px);
}

.blur-filter-xxl {
  animation: reblur 0.6s ease-out;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  &:hover {
    animation: unblur 0.6s ease-out;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
}
.fake-hover-blur {
  animation: unblur 0.6s ease-out;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}
@keyframes unblur {
  0% {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
  100% {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
}
@keyframes reblur {
  0% {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
}
.blur-filter-xx {
  backdrop-filter: blur(0.1em) !important;
  -webkit-backdrop-filter: blur(0.1em);
}
.blur-filter-xl {
  backdrop-filter: blur(3px) !important;
  -webkit-backdrop-filter: blur(3px);
}
.blur-filter-lg {
  backdrop-filter: blur(6px) !important;
  -webkit-backdrop-filter: blur(6px);
}
.blur-filter-md {
  backdrop-filter: blur(15px) !important;
  -webkit-backdrop-filter: blur(15px);
}
.blur-filter-sm {
  backdrop-filter: blur(40px) !important;
  -webkit-backdrop-filter: blur(40px);
}
.blur-filter-xs {
  backdrop-filter: blur(100px) !important;
  -webkit-backdrop-filter: blur(100px);
}
.bg-clear-dark-blur {
  background-color: rgba(0, 0, 0, 0.196);
  color: white !important;
  backdrop-filter: blur(6px) !important;
  -webkit-backdrop-filter: blur(6px);
}

.settings-gear {
  color: rgba(204, 204, 204, 0.251);

  &:hover {
    color: rgba(255, 255, 255, 0.801);
  }
}

$viewWidth: 50vw;

.test_sixteen_nine {
  width: ($viewWidth * 0.5);
  height: calc(($viewWidth * 0.5) / 16) * 9;
  background: #000;
}

.ses-card-users .MuiAvatarGroup-avatar {
  background-color: rgba(100, 100, 100, 0.5);
  font-size: small;
  width: 30px;
  height: 30px;
}
.loading-animation {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: 3;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: hsla(210, 12%, 97%, 0);
  background: linear-gradient(
    to right,
    rgba(238, 238, 238, 0.384) 8%,
    rgba(238, 238, 238, 0.26) 18%,
    rgba(238, 238, 238, 0.384) 33%
  );
  position: relative;
}
.loading-shimmer {
  // animation-duration: 10s;
  // animation-fill-mode: forwards;
  // animation-iteration-count: 3;
  // animation-name: placeHolderShimmer;
  // animation-timing-function: linear;
  overflow: clip;

  position: relative;
}
.loading-shimmer:after {
  position: absolute;
  top: 0px;
  left: 0px;
  content: "";
  width: 200%;
  height: 200%;
  background-image: linear-gradient(
    60deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0) 80%
  );
  transform: translateX(-100%);
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadShimmer;
  animation-timing-function: linear;
}

@keyframes loadShimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.training-stack {
  position: relative;
  // top: 50%;
  // left: 50%;
  // margin: -180px 0 0 -140px;
  width: fit-content;
  user-select: none;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  &.force-drop {
    // transform: rotate(-15deg);
    transition: all 0.5s ease-in-out;
    > div {
      margin-bottom: 120px !important;
      transform: rotateX(20deg) rotateY(0deg) rotate(0deg);
    }

    transform-style: preserve-3d;
  }
}
.training-stack:hover {
  // transform: rotate(-15deg);
  transition: all 0.5s ease-in-out;
  > div {
    margin-bottom: 120px !important;
    transform: rotateX(20deg) rotateY(0deg) rotate(0deg);
  }

  transform-style: preserve-3d;
}
.training-stack:after {
  position: relative;
  bottom: -27px;
  left: 5%;
  content: "";
  width: 30vw;
  height: 10px;
  border-radius: 100%;
  background-image: radial-gradient(
    rgba(34, 50, 84, 0.04),
    rgba(34, 50, 84, 0)
  );
}
.training-stack > div {
  position: relative;
  width: 280px;
  height: 180px;
  padding: 20px;
  border: 1px solid rgba(121, 121, 121, 0.959) !important;
  border-radius: 12px;
  color: #fff;
  transform: rotateX(35deg) rotateY(-0deg) rotateZ(-25deg) rotate(25deg);

  background-image: linear-gradient(
    315deg,
    rgba(19, 19, 19, 0.84) 0%,
    rgba(29, 32, 36, 0.84) 74%
  );
  box-shadow: rgb(10, 10, 10) 5px 10px 5px -6px;
  color: white !important;
  backdrop-filter: blur(3px) !important;
  -webkit-backdrop-filter: blur(3px);
  transition: all 0.4s ease;
  overflow: hidden;
  cursor: pointer;
}
.training-stack > div:after {
  position: absolute;
  top: -70px;
  left: -70px;
  content: "";
  width: 200%;
  height: 200%;
  background-image: linear-gradient(
    60deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0) 80%
  );
  transform: translateX(-100%);
}
.training-stack > div img {
  margin-top: 15px;
  pointer-events: none;
}
.training-stack > div h3 {
  position: absolute;
  bottom: 28px;
  left: 15px;
  font-size: 18px;
  font-weight: 800;
}

.training-stack > div.c-00 {
  margin-top: 0;
  margin-bottom: 7px;
  margin-right: 0;
  transform: rotateX(28deg) rotateY(0deg) rotate(0deg);
}
.training-stack > div.c-0 {
  margin-top: -160px;
  margin-right: 120px !important;
}
.training-stack > div.c-2 {
  margin-top: -160px;
  margin-right: 120px;
}
.training-stack > div.c-1 {
  margin-top: -160px;
  margin-right: 120px;
}
.training-stack > div.c-3 {
  opacity: 0.95;
  margin-top: -160px;
  margin-right: 120px;
}
.training-stack > div:hover {
  transform: rotateX(20deg) rotateY(0deg) rotate(0deg) translate(15px, 30px);
}

.training-stack > div:hover:after {
  transform: translateX(100%);
  transition: all 1.2s ease-in-out;
}
.stack-list {
  position: relative;
  // top: 50%;
  // left: 50%;
  //  margin: -180px 0 0 -140px;
  width: fit-content;
  user-select: none;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
}

.stack-list:after {
  position: relative;
  bottom: -27px;
  left: 5%;
  content: "";
}
.stack-list > div {
  position: relative;
  padding: 20px;
  animation: fadeIn 0.5s ease-in-out;
  transition: all 0.5s ease;
  &.stack-top {
    opacity: 1;
  }
  &.small-stack {
    &.not-stack-top {
      margin-top: -95px;
    }
  }
  &.not-stack-top {
    margin-top: -390px;
    opacity: 0.6;

    backdrop-filter: blur(16px) !important;
    -webkit-backdrop-filter: blur(16px);
  }

  border-radius: 12px;
  color: #fff;
  //transform-style: preserve-3d;

  transform: rotateX(25deg) rotateY(-10deg) rotateZ(5deg) rotate(-1deg);
  overflow: hidden;
  cursor: pointer;
}

.stack-list > div:after {
  position: absolute;
  top: -70px;
  left: -70px;
  content: "";
  width: 200%;
  height: 200%;
  background-image: linear-gradient(
    60deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0) 80%
  );
  transform: translateX(-100%);
}

.stack-list > div h3 {
  position: absolute;
  top: 28px;
  left: 15px;
  font-size: 18px;
  font-weight: 800;
}
.stack-list > div.s-0 {
  background-color: #fdfe73;
  background-image: linear-gradient(135deg, #11a7be, #bbbb4d);
  box-shadow: 10px 10px 60px rgba(#223254, 0.1), 1px 1px 0px 1px #bbbb4d;
}
.stack-list > div.s-1 {
  background-color: #1bb661;
  background-image: linear-gradient(135deg, #6ecc99, #1bb661);
  box-shadow: 10px 10px 60px rgba(#223254, 0.1), 1px 1px 0px 1px #1bb661;
}
.stack-list > div.s-2 {
  background-color: #2c6fd1;
  background-image: linear-gradient(135deg, #5cccfc, #2c6fd1);
  box-shadow: 10px 10px 60px rgba(#223254, 0.1), 1px 1px 0px 1px #2c6fd1;
}
.stack-list > div.s-3 {
  background-color: #352f64;
  background-image: linear-gradient(135deg, #415197, #352f64);
  box-shadow: 5px 5px 60px rgba(#223254, 0.1), 1px 1px 0px 1px #352f64;
}
div.s-1 > span {
  pointer-events: none;
}
div.s-0 > span {
  pointer-events: none;
}
div.s-2 > span {
  pointer-events: none;
}
div.s-3 > span {
  pointer-events: none;
}

.stack-list > div:hover:after {
  transform: translateX(100%);
  transition: all 1.2s ease-in-out;
}

.card-3d {
  position: relative;
  overflow: hidden;
  border-radius: 7px;
  color: white !important;
  background-color: #2c6fd1;
  background-image: linear-gradient(135deg, #5cccfc, #2c6fd1);
  box-shadow: 10px 10px 60px rgba(#223254, 0.1), 1px 1px 0px 1px #2c6fd1;
  &.selected-card-3d {
    &.true-card-3d {
      background-color: #1bb661;
      background-image: linear-gradient(135deg, #6ecc99, #1bb661);
      box-shadow: 10px 10px 60px rgba(#223254, 0.1), 1px 1px 0px 1px #1bb661;
    }
    &.false-card-3d {
      background-color: #fdfe73;
      background-image: linear-gradient(135deg, #11a7be, #bbbb4d);
      box-shadow: 10px 10px 60px rgba(#223254, 0.1), 1px 1px 0px 1px #bbbb4d;
    }
  }
}
.card-3d:after {
  position: absolute;
  top: 0px;
  left: 0px;
  content: "";
  width: 200%;
  height: 200%;
  background-image: linear-gradient(
    60deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0) 80%
  );
  transform: translateX(-100%);
}
.card-3d:hover {
  margin-top: 3px !important;
  margin-bottom: 9px;
  transform: rotateX(10deg);

  transform-style: preserve-3d;
  transition: all 1.2s linear;
}
.card-3d:hover:after {
  transform: translateX(100%);
  transition: all 1.2s cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.bg-info-wait {
  background-color: rgba(36, 93, 117, 0.322);
}
@keyframes btnShimmer {
  0% {
    background-position: 0% 0;
  }

  100% {
    background-position: -25% 0;
  }
}

.menu-icon {
  $size: 30px;
  $line-size: 11px;
  $radius: 5px;
  $color: rgb(226, 226, 226);

  border: 1px solid #333;
  border-radius: $radius;
  height: $size;
  cursor: pointer;
  left: 50%;
  position: absolute;
  //margin: -20px 0 0 -20px;
  top: 0%;
  transition: all 200ms ease-out;
  width: $size;

  &:before {
    background-color: $color;
    content: "";
    height: 1px;
    left: 25%;
    top: 50%;

    transition: all 400ms cubic-bezier(0.25, 0.6, 0.36, 1);
    position: absolute;
  }
  &:after {
    background-color: $color;
    content: "";
    height: 1px;
    left: 75%;
    top: 50%;

    transition: all 400ms cubic-bezier(0.25, 0.6, 0.36, 1);
    position: absolute;
  }
  &.up-arrow {
    &:after {
      left: 75%;
      top: 50%;
      transform: translate3d(-75%, -50%, 0) rotate(45deg);

      width: $line-size;
    }
    &:before {
      left: 25%;
      top: 50%;

      transform: translate3d(-25%, -50%, 0) rotate(-45deg);
      width: $line-size;
    }
  }
  > .line {
    background: #00000000;

    overflow: clip;
    position: absolute;
    transition: all 0.1s cubic-bezier(0.46, 0.03, 0.52, 0.96);

    &.right {
      border: 1px solid $color;
      border-radius: $radius;
      height: $size;

      left: 50%;
      position: absolute;
      margin: -20px 0 0 -20px;
      top: 50%;
      transition: all 200ms ease-out;
      width: $size;
      opacity: 0;
      transform: translate3d(1px, 0, 0) translateX(18%) translateY(19%);
      transform-origin: 0;
      transition-delay: 0.2s;
    }
  }

  &.down-arrow {
    &:after {
      height: 2px;
      width: $line-size + 1px;
      left: 75%;
      transition-delay: 0.1s;
      transform: translate3d(-75%, -40%, 0) rotate(135deg);
    }
    &:before {
      height: 2px;
      width: $line-size + 1px;
      left: 25%;
      transition-delay: 0.1s;
      transform: translate3d(-25%, -40%, 0) rotate(-135deg);
    }
  }
  &:active {
    opacity: 0.8;
    transition: none;
  }
  &:hover {
    border: 1px solid $color;
    transition: border 0.4s ease-out;
  }
}

.box-shadow-3d {
  box-shadow: inset -1px -1px 4px 1px rgba(26, 26, 26, 0.418),
    inset 1px 1px 8px 1px rgba(199, 199, 199, 0.082), 3px 3px 10px 6px #01010175,
    -2px -4px 9px 3px #dadada0c;
}
.logo-shadow-3d-w-blur {
  transition: all 0.5s ease-in;
  filter: blur(5px);
  &:hover {
    filter: none;
    transition: all 0.5s ease-in;
  }
  &.selected-logo-jsc {
    transition: all 0.5s ease-in;
    filter: drop-shadow(5px 3px 2px #010101b4)
      drop-shadow(-5px -4px 4px #dadada1c);
    transform: rotate3d(1, -2, 0, 20deg);
    animation: pulsedropshadowblue 5s ease-in-out infinite;
    // transform-style: preserve-3d;
  }
  &.selected-logo-jm {
    transition: all 0.5s ease-in;
    filter: drop-shadow(5px 3px 2px #010101b4)
      drop-shadow(-5px -4px 4px #dadada1c);
    transform: rotate3d(1, -2, 0, 20deg);
    animation: pulsedropshadowyellow 5s ease-in-out infinite;
    // transform-style: preserve-3d;
  }
}
@keyframes pulsedropshadowblue {
  0% {
    //transform: scaleX(100%);
    filter: drop-shadow(5px 3px 2px #010101b4)
      drop-shadow(-5px -4px 4px #dadada1c);
  }
  50% {
    //transform: scaleX(0%);
    filter: drop-shadow(5px 3px 2px #010101b4)
      drop-shadow(-5px -4px 4px #dadada1c) drop-shadow(5px 3px 2px #035569b4);
  }
  100% {
    //transform: scaleX(100%);
    filter: drop-shadow(5px 3px 2px #010101b4)
      drop-shadow(-5px -4px 4px #dadada1c);
  }
}
@keyframes pulsedropshadowyellow {
  0% {
    //transform: scaleX(100%);
    filter: drop-shadow(5px 3px 2px #010101b4)
      drop-shadow(-5px -4px 4px #dadada1c);
  }
  50% {
    //transform: scaleX(0%);
    filter: drop-shadow(5px 3px 2px #010101b4)
      drop-shadow(-5px -4px 4px #dadada1c) drop-shadow(5px 3px 2px #695f03b4);
  }
  100% {
    //transform: scaleX(100%);
    filter: drop-shadow(5px 3px 2px #010101b4)
      drop-shadow(-5px -4px 4px #dadada1c);
  }
}
.logo-shadow-3d {
  transition: all 0.5s ease-in;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  &:hover {
    transition: all 0.5s ease-in;
    filter: drop-shadow(5px 3px 2px #010101b4)
      drop-shadow(-5px -4px 4px #dadada1c);
    transform: rotate3d(1, -2, 0, 20deg);
    transform-style: preserve-3d;
  }
}

.program-btn {
  &.box-shadow-3d {
    transition: all 1s ease-out;
    box-shadow: inset -1px -1px 4px 2px rgba(26, 26, 26, 0.514),
      inset 1px 1px 8px 3px rgba(199, 199, 199, 0.137),
      5px 1px 8px 10px #01010159, -2px -4px 9px 3px #dadada0e;
    transform: skewX(-1deg) rotate3d(2, -5, 0, -10deg) !important;
    transform-style: preserve-3d;
  }
}

// .cool-effect{
//   transition: all 1s ease-out;
// box-shadow:
// inset -1px -1px 4px 2px rgba(26, 26, 26, 0.514),
// inset 1px 1px 8px 3px rgba(199, 199, 199, 0.137),
// 6px 6px 1px 1px #1e3842b2,
// 13px 12px 1px 1px #37204694,
// 17px 17px 1px 1px #243846a2,
// 18px 18px 10px 10px #01010159,
// -2px -4px 9px 3px #dadada0e;
//     transform: skewX(-1deg) rotate3d(2,-5,0,-20deg) !important;
//     transform-style: preserve-3d;
// }

// $b1cd: #500033;
// $b1cl: #FF0077;
// $b2cd: #000050;
// $b2cl: #0033FF;
// $b3cd: #00501D;
// $b3cl: #00FF44;
// $b4cd: #554D00;
// $b4cl: #FF4E00;
// $b5cd: #300050;
// $b5cl: #8000FF;
// @mixin color_render($DC, $LC) {
//   background-color: $DC;
//   .illustration .inner {
//       background-color: $LC;
//       &::after, &::before{ background-color: rgba($LC, .4);}
//   }
//   button {background-color: $LC;}
// }
// .box {
//   height: 100%;
//   width: 100%;
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   align-items: center;
//   overflow: hidden;
//   position: relative;
// padding-top: 100px !important;
//   @media only screen and (max-width: 650px) {
//       grid-template-columns: 1fr;
//       grid-template-rows: repeat(2, 1fr);
//   }
//   .box4 {@include color_render($b4cd, $b4cl);}
//   .illustration .inner {
//     background-color: $b4cl;
//     &::after, &::before{ background-color: rgba($b4cl, .4);}
// }
// .illustration {
//   grid-column: 2 / -1;
//   grid-row: 1 / -1;
//   justify-self: center;

//   @media only screen and (max-width: 650px) {
//       grid-row: 1 / span 1;
//       grid-column: 1 / -1;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//   }

//   div {
//       height: 25rem;
//       width: 18rem;
//       border-radius: 3rem;
//       background-color: $b1cl;
//       position: relative;
//       transform: skewX(-10deg);

//       @media only screen and (max-width: 800px) {
//           height: 20rem;
//           width: 15rem;
//       }

//       &::after,
//       &::before {
//           content: "";
//           position: absolute;
//           height: 100%;
//           width: 100%;
//           border-radius: 3rem;
//           top: 0;
//           left: 0;
//       }

//       &::after {transform: translate(4rem, -1rem);}
//       &::before {transform: translate(2rem, -2rem);}
//   }
// }
// }

.progress-bar {
  width: 100%;

  padding: 3px;
  box-shadow: inset -1px -1px 4px 2px rgba(26, 26, 26, 0.514),
    inset 1px 1px 8px 3px rgba(199, 199, 199, 0.137), 5px 1px 8px 10px #01010159,
    -2px -4px 9px 3px #dadada0e;
}

.progress-bar-fill {
  display: block;
  height: 22px;
  background-color: #128491;
  border-radius: 3px;
  animation: progressbarfill 7s 1;
  animation-timing-function: cubic-bezier(0.83, 1.21, 1, 0.29) !important;
  // animation-timing-function: cubic-bezier(.1,.49,.76,.12) !important;
}

@keyframes progressbarfill {
  0% {
    width: 1%;
    min-width: 1%;
  }
  100% {
    min-width: 10px;
    width: 100%;
  }
}

.form-error {
  box-shadow: inset -1px -1px 4px 1px rgba(107, 2, 2, 0.521),
    inset 1px 1px 8px 1px rgba(194, 63, 63, 0.432), 3px 3px 10px 6px #1f000038,
    -2px -4px 9px 3px #c4626215;
}

.pulsing-svg {
  transition: all 0.5s ease-in;
  filter: drop-shadow(5px 3px 2px #010101b4)
    drop-shadow(-5px -4px 4px #dadada1c);
  animation: pulsedropshadowbluestrong 3s ease-in-out infinite;
  // transform-style: preserve-3d;
}
@keyframes pulsedropshadowbluestrong {
  0% {
    //transform: scaleX(100%);
    filter: drop-shadow(5px 3px 2px #010101b4)
      drop-shadow(-5px -4px 4px #dadada1c);
  }
  50% {
    //transform: scaleX(0%);
    // transform: rotate3d(1, -2, 0, 20deg);
    filter: drop-shadow(5px 3px 2px #010101b4)
      drop-shadow(-5px -4px 4px #dadada1c) drop-shadow(2px 1px 1px #00a5ced2);
  }
  100% {
    //transform: scaleX(100%);
    filter: drop-shadow(5px 3px 2px #010101b4)
      drop-shadow(-5px -4px 4px #dadada1c);
  }
}

.button-w-hover-text {
  position: relative;

  > .hover-text {
    > div {
      width: 20vw;
      max-width: max-content;
    }
    overflow: clip;
    border-radius: 4px;
    opacity: 0;
    width: 0;
    max-width: fit-content;
    padding: 2px 6px;
    pointer-events: none;

    position: absolute;
    top: 50%;
    left: 110%;
    transform: translateY(-50%);
    transition: all 0.5s ease-out;
    transition-delay: 0.2s;
    animation: hoverlabelshrink 0.7s ease-in-out;
  }
  &:hover {
    > .hover-text {
      opacity: 0.98;
      > div {
        width: max-content;
      }
      // display: unset;
      @extend .box-shadow-3d;

      background-color: #128491;
      max-width: max-content;
      width: max-content;
      transform: translateY(-50%);
      transition: all 0.5s ease-out;
      animation: hoverlabelgrow 1.5s ease-out 1;
    }
  }
}

@keyframes hoverlabelgrow {
  0% {
    width: 0;
  }

  100% {
    width: 50vw;
  }
}
@keyframes hoverlabelshrink {
  0% {
    max-width: max-content;
    width: 50vw;
    background-color: rgba(85, 85, 85, 0.63);
  }

  100% {
    width: 0;
  }
}

.video-with-ratio {
  aspect-ratio: var(--ratio) !important;
}

.d-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(300px, 20%), 1fr)
  ) !important;
  grid-template-rows: repeat(auto-fill, 1fr) !important;
  gap: 1rem;
  align-items: stretch;
}

.participant-container-small {
  width: 100%;
  max-width: 20vw;
  justify-self: center;
  aspect-ratio: 16/9;
  padding: 0 5px;
  display: grid;
  row-gap: 10px;
  grid-template-columns: 100%;
  grid-template-rows: max-content max-content;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}

.video-chat-grid {
  display: grid;
  margin: 0 auto;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(min(150px, 100%), 1fr));
  row-gap: 5px;
  align-items: stretch;
  justify-content: stretch;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  &.large-grid {
    width: 75%;
    max-width: min(1400px, 66%);
    grid-template-columns: repeat(auto-fit, minmax(min(20%, 100%), 1fr));
  }
}

.inset-box-shadow {
  box-shadow: inset -5px -27px 12px -10px rgba(0, 0, 0, 0.308),
    inset -5px 27px 12px -10px rgba(0, 0, 0, 0.219) !important;
  // box-shadow: inset -5px -27px 12px -10px rgba(0, 0, 0, 0.308) !important;
}

dl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  color: whitesmoke;
}
dl * {
  position: absolute;

  margin: auto;
}

dt {
  width: var(--size);
  height: var(--size);
  font-size: calc(var(--size) - 4vmin);
  opacity: 0;
  transform: translateY(100%);
  animation: dt 45s cubic-bezier(0.23, 1, 0.32, 1) infinite;
}
dt:after {
  content: "";
  position: absolute;
  top: 1vmin;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform-origin: 50% 100%;
  animation: dt-pseudo 45s cubic-bezier(0.19, 1, 0.22, 1) infinite;
}

dd {
  width: 100%;
  height: 100%;
  opacity: 0;
  translate: translateX(-50%) !important;
  animation: dd 60s cubic-bezier(0.22, 1, 0.22, 1) infinite;
}
dd:nth-child(1) {
  animation-delay: 0s;
}
dd:nth-child(2) {
  animation-delay: 7.5s;
}
dd:nth-child(3) {
  animation-delay: 15s;
}
dd:nth-child(4) {
  animation-delay: 22.5s;
}
dd:nth-child(5) {
  animation-delay: 30s;
}
dd:nth-child(6) {
  animation-delay: 37.5s;
}
dd:nth-child(7) {
  animation-delay: 45s;
}
dd:nth-child(8) {
  animation-delay: 52.5s;
}

@keyframes dt {
  2.5% {
    transform: translateY(0);
    opacity: 1;
  }
  5% {
    transform: translateY(0);
    opacity: 1;
  }
  7.5% {
    transform: translateY(2%);
    opacity: 0;
  }
}
@keyframes dt-pseudo {
  2.5% {
    transform: scale(1, 0.1);
  }
  5% {
    transform: scale(1, 0.1);
  }
  7.5% {
    transform: scale(0, 0.1);
  }
}
@keyframes dd {
  5% {
    transform: translateY(0);
    opacity: 1;
  }
  11% {
    transform: translateY(0);
    opacity: 1;
  }

  23% {
    transform: translateY(2%);
    opacity: 0;
  }
}

.fix-padding-4 {
  &:hover {
    padding-bottom: 4px !important;
  }
}
